import Modal from 'app/components/partials/modal';
import NewsLetterSignUpForm from './news-letter-signup-form';
import PropTypes from 'prop-types';
import React from 'react';

const NewsLetterModal = ({ modalStatus, modalHander, emailValue }) => {
    return (
        <Modal isActive={modalStatus} closeModalHandler={() => modalHander(false)}>
            <NewsLetterSignUpForm
                emailValue={emailValue}
                closeModalHandler={() => modalHander(false)}
            />
        </Modal>
    );
};

NewsLetterModal.propTypes = {
    modalStatus: PropTypes.bool.isRequired,
    modalHander: PropTypes.func.isRequired,
    emailValue: PropTypes.string.isRequired
};

export default NewsLetterModal;
