/* eslint-disable camelcase */
import * as Yup from 'yup';
import { clientEnv } from 'config/env';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import Input from 'app/components/partials/input';
import Layout from './layout';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import React, { useState } from 'react';

const NEWSLETTER_OPTIONS = [
    { label: 'I work in Early Childhood Education', value: 'Early Childhood Education' },
    { label: 'I work in Primary Education', value: 'Primary Education' },
    { label: 'I work in Secondary Education', value: 'Secondary Education' },
    { label: 'I work in Tertiary Education', value: 'Tertiary Education' },
    { label: 'I work in Community Education', value: 'Community Education' },
    { label: 'I work in another art institution', value: 'Art Institution' },
];

const SignupForm = ({ setSubmitted }) => {
    const formik = useFormik({
        initialValues: {
            email: '',
            email_confirmation: '',
            firstname: '',
            lastname: '',
            consent: false,
            newsletters: [],
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Not a valid email address').required('Email is required'),
            email_confirmation: Yup.string().oneOf([Yup.ref('email'), null], 'Emails don\'t match'),
            firstname: Yup.string().required('First Name is required'),
            lastname: Yup.string().required('Last Name is required'),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // reset error
            setStatus('');
            setSubmitting(true);
            // eslint-disable-next-line no-undef
            grecaptcha.ready(async() => {
                // eslint-disable-next-line no-undef
                const captchaToken = await grecaptcha.execute(clientEnv.RECAPTCHA_V3_KEY, { action: 'education_signup' });
                try {
                    await fetch(`${ENDPOINTS.EDUCATION_SIGNUP}`, null, {
                        method: 'POST',
                        body: JSON.stringify({
                            ...values,
                            captchaToken,
                        }),
                    });
                    // Display confirmation
                    setSubmitted(true);
                    // Scroll to top to show message
                    window.scrollTo(0, 0);
                } catch (e) {
                    // Display generic error message
                    setStatus(`Something went wrong, please try again later (${e.status})`);
                } finally {
                    setSubmitting(false);
                }
            });
        },
    });

    const toggleAllNewsletters = (e) => {
        const val = e.target.checked ? NEWSLETTER_OPTIONS.map((option) => option.value) : [];
        formik.setFieldValue('newsletters', val);
    };

    const baseInputProps = {
        className: 'input',
        onChangeHandler: formik.handleChange,
        onBlurHandler: formik.handleBlur,
        isRequired: true,
    };

    const emailInputProps = {
        ...baseInputProps,
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address (required)',
        value: formik.values.email,
        error: formik.touched.email && formik.errors.email,
    };
    const emailConfirmInputProps = {
        ...baseInputProps,
        type: 'email',
        name: 'email_confirmation',
        id: 'email_confirmation',
        placeholder: 'Confirm Email Address (required)',
        value: formik.values.email_confirmation,
        error: formik.touched.email_confirmation && formik.errors.email_confirmation,
    };
    const firstNameInputProps = {
        ...baseInputProps,
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'First Name (required)',
        value: formik.values.firstname,
        error: formik.touched.firstname && formik.errors.firstname,
    };
    const lastNameInputProps = {
        ...baseInputProps,
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Last Name (required)',
        value: formik.values.lastname,
        error: formik.touched.lastname && formik.errors.lastname,
    };

    return (
        <div className="education-signup">
            <h2>Subscribe for Email Updates</h2>
            <form className="form" onSubmit={formik.handleSubmit}>
                <Input {...firstNameInputProps} />
                <Input {...lastNameInputProps} />
                <Input {...emailInputProps} />
                <Input {...emailConfirmInputProps} />

                <p>By signing up with a generic email address (e.g., info@, admin@, etc.) you confirm that you are authorised to receive this newsletter on behalf of your school or organisation, acknowledge that this email address may be shared among staff members, and understand that unsubscribing will apply to the entire organisation.</p>

                <div className="field-group || checkbox || checkbox-consent">
                    <label>
                        <input type="checkbox" name="consent" value="true" checked={formik.values.consent} onChange={formik.handleChange} />
                        <span>I agree to receiving the Education Newsletter from Auckland Art Gallery by email.</span>
                    </label>
                </div>

                {formik.values.consent &&
                    <div>
                        <h6>Select one that best describes you:</h6>
                        <div className="select-topics">
                            {NEWSLETTER_OPTIONS.map((option, index) => (
                                <div key={index} className="field-group || checkbox">
                                    <label>
                                        <input type="checkbox" name="newsletters" value={option.value} checked={formik.values.newsletters.includes(option.value)} onChange={formik.handleChange} />
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                            ))}
                            <div className="field-group || checkbox">
                                <label>
                                    <input type="checkbox" name="newsletters_all" value="all" checked={formik.values.newsletters.length === NEWSLETTER_OPTIONS.length} onChange={toggleAllNewsletters} />
                                    <span>All of the above</span>
                                </label>
                            </div>
                        </div>
                        <p>By clicking Submit, I understand that my personal information will be used to send me the newsletter and I can withdraw my consent at any time by using the unsubscribe link provided in each email. To find out more about how we manage and protect your personal information, please review the Auckland Art Gallery <Link to="/page/privacy-policy">Privacy Policy</Link> and <Link to="/page/terms-and-conditions">Terms and Conditions</Link>.</p>
                    </div>
                }

                {formik.status && <div className="error-message">{formik.status}</div>}

                <div className="button-group left-align">
                    <button type="submit" className="button primary" disabled={formik.isSubmitting || !formik.values.consent}>Submit</button>
                </div>
            </form>
        </div>
    );
};
SignupForm.propTypes = {
    setSubmitted: PropTypes.func.isRequired
};

const EducationSignupPage = () => {
    const [submitted, setSubmitted] = useState(false);

    return (
        <Layout pageAttrs={{
            seo_title: 'Signup for Education Newsletters'
        }}>
            <div className="content-page constrain-width">
                {!submitted &&
                    <SignupForm setSubmitted={setSubmitted} />
                }
                {submitted &&
                    <div>
                        <h2>Thanks for signing up</h2>
                        <p>We’ll be sharing the latest education news from the Gallery. You can change your email preferences at any time by following the link at the bottom of our newsletters.</p>
                    </div>
                }
            </div>
        </Layout>
    );
};

export default EducationSignupPage;
