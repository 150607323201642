import NewsLetterSignUpForm from '../partials/news-letter-signup-form';
import React from 'react';

const NewsLetterSignUp = () => {
    return (
        <main class="page" role="main">
            <div className="news-letter-sign-up">
                <div className="constrain-width">
                    <NewsLetterSignUpForm />
                </div>
            </div>
        </main>
    );
};

export default NewsLetterSignUp;
