import * as Yup from 'yup';
import { clientEnv } from 'config/env';
import fetch from 'app/utilities/fetch';
import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ENDPOINTS, ERROR_MESSAGE } from 'config/api';
import React, { useEffect } from 'react';

const NewsLetterSignUpForm = ({
    emailValue = '',
    // provide extra buttons for closing modal if needed
    closeModalHandler,
}) => {
    const submitSuccessfullyHandler = () => {
        formik.resetForm();
        closeModalHandler();
    };

    const renderSubmitSuccessfully = () => (
        <div>
            <h2>THANKS FOR SIGNING UP</h2>
            <p>We’ll be sharing the latest news from the Gallery and our online shop. You can change your email preferences at any time by following the link at the bottom of our newsletters.</p>
            {closeModalHandler &&
                <button className="button primary wide" onClick={submitSuccessfullyHandler}>OK</button>
            }
        </div>
    );

    const formik = useFormik({
        initialValues: {
            email: '',
            emailconfirm: '',
            firstname: '',
            lastname: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Not a valid email address'),
            emailconfirm: Yup.string().oneOf([Yup.ref('email'), null], 'Emails don\'t match'),
            firstname: Yup.string().required('First Name is required'),
            lastname: Yup.string().required('Last Name is required'),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // reset error
            setStatus();
            setSubmitting(true);
            // eslint-disable-next-line no-undef
            grecaptcha.ready(async() => {
                // eslint-disable-next-line no-undef
                const captchaToken = await grecaptcha.execute(clientEnv.RECAPTCHA_V3_KEY, { action: 'newsletter' });
                try {
                    await fetch(`${ENDPOINTS.NEWSLETTER_SIGNUP}`, null, {
                        method: 'POST',
                        body: JSON.stringify({
                            ...values,
                            captchaToken,
                        }),
                    });
                    // Display confirmation
                    setStatus({ success: true });
                } catch (e) {
                    // Display generic error message
                    setStatus({ error: `${ERROR_MESSAGE.INTERNAL_SERVER_ERROR} (${e.status})` });
                } finally {
                    setSubmitting(false);
                }
            });
        }
    });

    useEffect(() => {
        // Sync initial email value on parent modal open and validate form
        formik.setTouched({ email: true }, true);
        formik.setValues({ email: emailValue }, true);
    }, [emailValue]);

    const renderNewsletterSignupForm = () => {
        const baseInputProps = {
            className: 'input',
            onChangeHandler: formik.handleChange,
            onBlurHandler: formik.handleBlur,
            isRequired: true,
        };

        const emailInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'email',
            id: 'email',
            placeholder: 'Enter your email',
            value: formik.values.email,
            error: formik.touched.email && formik.errors.email,
        };
        const emailConfirmInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'emailconfirm',
            id: 'emailconfirm',
            placeholder: 'Confirm your email',
            value: formik.values.emailconfirm,
            error: formik.touched.emailconfirm && formik.errors.emailconfirm,
        };
        const firstNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'firstname',
            id: 'firstname',
            placeholder: 'First Name',
            value: formik.values.firstname,
            error: formik.touched.firstname && formik.errors.firstname,
        };
        const lastNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'lastname',
            id: 'lastname',
            placeholder: 'Last Name',
            value: formik.values.lastname,
            error: formik.touched.lastname && formik.errors.lastname,
        };

        return (
            <div className="newsletter-signup">
                <h2>Be in the know</h2>
                <p>Sign up to our newsletter to stay up to date</p>
                <form className="form newsletter-signup-form" onSubmit={formik.handleSubmit}>
                    <Input {...emailInputProps} />
                    <Input {...emailConfirmInputProps} />
                    <Input {...firstNameInputProps} />
                    <Input {...lastNameInputProps} />

                    <div className='privacy-container'>
                        <Link className="link privacy-policy-link" to="/page/copyright-and-privacy">
                            Read about our privacy policy
                        </Link>
                    </div>
                    {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}

                    <div className="button-group left-align">
                        {closeModalHandler &&
                            <button className="button default" type="button" onClick={closeModalHandler}>Cancel</button>
                        }
                        <button type="submit" className="button primary" disabled={formik.isSubmitting}>Submit</button>
                    </div>
                </form>
            </div>
        );
    };

    if (formik.status && formik.status.success) {
        return renderSubmitSuccessfully();
    }

    return renderNewsletterSignupForm();
};

NewsLetterSignUpForm.propTypes = {
    emailValue: PropTypes.string,
    closeModalHandler: PropTypes.func,
};

export default NewsLetterSignUpForm;
