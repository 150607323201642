import AdvancedCollectionSearchPage from './components/pages/advanced-collection-search-page';
import ArticlesPage from './components/pages/articles-page';
import ContentPage from './components/pages/content-page.container';
import EducationSignupPage from './components/pages/education-signup.jsx';
import ErrorPage from './components/pages/error/error-page';
import Footer from './components/partials/footer';
import GlobalSearchPage from './components/pages/global-search-page';
import Header from './components/partials/header';
import NewsLetterSignUp from './components/pages/news-letter-sign-up.jsx';
import NotFoundPage from './components/pages/error/not-found-page';
import React from 'react';
import ScrollToTop from './components/partials/scroll-to-top';
import SearchPage from './components/pages/search-page';
import StyleGuidePage from './components/pages/style-guide-page';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import { Route, Routes } from 'react-router-dom';

const Router = () => (
    <div className="base">
        <ScrollToTop />
        <Routes>
            <Route path="/s" element={<></>} />
            <Route path="*" element={<Header />} />
        </Routes>

        <Routes>
            {/* Custom */}
            <Route path="/style-guide" element={<StyleGuidePage />} />
            <Route path="/error-500" element={<ErrorPage />} />
            <Route path="/articles" element={<ArticlesPage />} />
            <Route path="/explore-art-and-ideas/advanced-collection-search" element={<AdvancedCollectionSearchPage />} />
            <Route path="/news-letter/sign-up" element={<NewsLetterSignUp />} />
            {/* temporarily hide education signup page */}
            {/* <Route path="/education/signup" element={<EducationSignupPage />} /> */}
            {/* Define events and exhibition routes explicitly so they don't get overriden by whats-on dynamic params below */}
            <Route path="/whats-on/event/:slug" element={<ContentPage />} />
            <Route path="/whats-on/exhibition/:slug" element={<ContentPage />} />
            {/* Hacky way to support dynamic params on What's on page */}
            <Route path="/whats-on/:dateRange" element={<ContentPage />} />
            <Route path="/whats-on/:dateRange/:eventType" element={<ContentPage />} />
            <Route path="/whats-on/:dateRange/:eventType/:searchKeyword" element={<ContentPage />} />
            {/* API Based Routes */}
            <Route path="/search/:reference" element={<SearchPage />} />
            <Route path="/s" element={<GlobalSearchPage />} />
            <Route path="/" element={<ContentPage />} />
            <Route path="/explore-art-and-ideas/archives/:collectionId/item/:itemId" element={<ContentPage />} />
            <Route path="/:slug" element={<ContentPage />} />
            <Route path="/:parent/:slug" element={<ContentPage />} />
            <Route path="/:grandParent/:parent/:slug" element={<ContentPage />} />
            {/* vernon entity detail pages accept both id and slug */}
            <Route path="/:grandParent/:parent/:id/:slug" element={<ContentPage />} />
            {/* Catch */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
    </div>
);

export default throttleUpdateWhileRouting(Router);
